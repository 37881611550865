<script lang="ts" setup>
import type { Component } from 'vue'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import VerticalNavGroup from './VerticalNavGroup.vue'
import VerticalNavLink from './VerticalNavLink.vue'
import VerticalNavSectionTitle from './VerticalNavSectionTitle.vue'
import beta from '@svg/misc/beta.svg'
import { localeLink } from '#imports'
import { injectionKeyIsVerticalNavHovered } from '~~/plugins/layouts'
import type { VerticalNavItems, NavLink, NavSectionTitle, NavGroup } from '~~/types/dvn-layouts'

interface Props {
  tag?: string | Component
  navItems: VerticalNavItems
  isOverlayNavActive: boolean
  toggleIsOverlayNavActive: (value: boolean) => void
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'aside',
})

const { $themeConfig } = useNuxtApp()
const { siteName } = useRuntimeConfig().public
const { isVerticalNavCollapsed, isLessThanOverlayNavBreakpoint, isVerticalNavMini, isAppRtl } =
  useThemeConfig()
const refNav = ref()
const { width: windowWidth } = useWindowSize()
const isHovered = useElementHover(refNav)

provide(injectionKeyIsVerticalNavHovered, isHovered)

const hideTitleAndIcon = isVerticalNavMini(windowWidth, isHovered)

const resolveNavItemComponent = (item: NavLink | NavSectionTitle | NavGroup): Component => {
  if ('heading' in item) return VerticalNavSectionTitle
  if ('children' in item) return VerticalNavGroup
  return VerticalNavLink
}

/*
  ℹ️ Close overlay side when route is changed
  Close overlay vertical nav when link is clicked
*/
const route = useRoute()

watch(
  () => route.name,
  () => {
    props.toggleIsOverlayNavActive(false)
  },
)

const isVerticalNavScrolled = ref(false)
const updateIsVerticalNavScrolled = (val: boolean) => (isVerticalNavScrolled.value = val)

const handleNavScroll = (evt: Event) => {
  isVerticalNavScrolled.value = (evt.target as HTMLElement).scrollTop > 0
}
</script>

<template>
  <Component
    :is="props.tag"
    ref="refNav"
    class="layout-vertical-nav no-selection"
    :class="[
      {
        'overlay-nav': isLessThanOverlayNavBreakpoint(windowWidth),
        hovered: isHovered,
        visible: isOverlayNavActive,
        scrolled: isVerticalNavScrolled,
      },
    ]"
  >
    <!-- 👉 Header -->
    <div class="nav-header">
      <slot name="nav-header">
        <NuxtLink
          :to="localeLink('/')"
          target="_blank"
          rel="noopener noreferrer"
          class="app-logo d-flex align-center app-title-wrapper"
        >
          <LayoutsLogo height="30" />
          <Transition name="vertical-nav-app-title">
            <!-- <h1
              v-show="!hideTitleAndIcon"
              class="font-weight-semibold leading-normal text-xl text-uppercase"
            >
              {{ siteName }}
            </h1> -->
            <LayoutsLogoCut height="28" />
          </Transition>
          <beta class="beta" />
        </NuxtLink>
        <!-- 👉 Vertical nav actions -->
        <!-- Show toggle collapsible in >md and close button in <md -->
        <template v-if="!isLessThanOverlayNavBreakpoint(windowWidth)">
          <Component
            :is="$themeConfig.app.iconRenderer || 'div'"
            v-show="isVerticalNavCollapsed && !hideTitleAndIcon"
            class="header-action"
            v-bind="$themeConfig.icons.verticalNavUnPinned"
            @click="isVerticalNavCollapsed = !isVerticalNavCollapsed"
          />
          <Component
            :is="$themeConfig.app.iconRenderer || 'div'"
            v-show="!isVerticalNavCollapsed && !hideTitleAndIcon"
            class="header-action"
            v-bind="$themeConfig.icons.verticalNavPinned"
            @click="isVerticalNavCollapsed = !isVerticalNavCollapsed"
          />
        </template>
        <template v-else>
          <Component
            :is="$themeConfig.app.iconRenderer || 'div'"
            class="header-action"
            v-bind="$themeConfig.icons.close"
            @click="toggleIsOverlayNavActive(false)"
          />
        </template>
      </slot>
    </div>
    <slot name="before-nav-items">
      <div class="vertical-nav-items-shadow" />
    </slot>
    <slot name="nav-items" :update-is-vertical-nav-scrolled="updateIsVerticalNavScrolled">
      <PerfectScrollbar
        tag="ul"
        class="nav-items"
        :options="{ wheelPropagation: false }"
        @ps-scroll-y="handleNavScroll"
      >
        <Component
          :is="resolveNavItemComponent(item)"
          v-for="(item, index) in navItems"
          :key="index"
          :item="item"
        />
      </PerfectScrollbar>
    </slot>
  </Component>
</template>

<style lang="css" scoped>
.beta {
  position: relative;
  left: 5px;
  bottom: -7px;
  height: 15px;
}
</style>

<style lang="scss">
@use '@configured-variables' as variables;
@use '@styles/layouts/mixins';

// 👉 Vertical Nav
.layout-vertical-nav {
  position: fixed;
  z-index: variables.$layout-vertical-nav-z-index;
  display: flex;
  flex-direction: column;
  block-size: 100%;
  inline-size: variables.$layout-vertical-nav-width;
  inset-block-start: 0;
  inset-inline-start: 0;
  transition:
    transform 0.25s ease-in-out,
    inline-size 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out;
  will-change: transform, inline-size;

  .nav-header {
    display: flex;
    align-items: center;

    .header-action {
      cursor: pointer;
    }
  }

  .app-title-wrapper {
    margin-inline-end: auto;
  }

  .nav-items {
    block-size: 100%;

    // ℹ️ We no loner needs this overflow styles as perfect scrollbar applies it
    // overflow-x: hidden;

    // // ℹ️ We used `overflow-y` instead of `overflow` to mitigate overflow x. Revert back if any issue found.
    // overflow-y: auto;
  }

  .nav-item-title {
    overflow: hidden;
    margin-inline-end: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // 👉 Collapsed
  .layout-vertical-nav-collapsed & {
    &:not(.hovered) {
      inline-size: variables.$layout-vertical-nav-collapsed-width;
    }
  }

  // 👉 Overlay nav
  &.overlay-nav {
    &:not(.visible) {
      transform: translateX(-#{variables.$layout-vertical-nav-width});

      @include mixins.rtl {
        transform: translateX(variables.$layout-vertical-nav-width);
      }
    }
  }
}
</style>

<style lang="css" scoped>
.no-selection {
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
</style>

<script setup lang="ts">
import { localeLink } from '#imports'
const config = useRuntimeConfig()
const { t } = useI18n()
const { $ipc } = useNuxtApp()
const isVisible = ref(false)

const logfile = ref('')

let system: Record<string, any>
let debug: boolean

if ($ipc) {
  const info = await $ipc.getSystemInfo()
  system = info.system
  debug = info.debug
}

async function openSystemInfo() {
  if ($ipc) {
    const info = await $ipc.getSystemInfo()
    logfile.value = info.logfile
    isVisible.value = true
  }
}
</script>

<template>
  <div class="h-100 d-flex align-center justify-space-between footer-font">
    <!-- 👉 Footer: left content -->
    <span class="d-flex align-center">
      &copy;
      {{ new Date().getFullYear() }}
      made with
      <VIcon icon="mdi-heart-outline" color="error" size="1.2em" class="mx-1" />
      by
      <NuxtLink
        :to="localeLink('/')"
        target="_blank"
        rel="noopener noreferrer"
        class="text-primary ms-1"
        >DiViNe Samples</NuxtLink
      >
    </span>
    <!-- 👉 Footer: center content -->
    <span v-if="$ipc" class="d-md-flex d-none">
      <NuxtLink :to="{}" class="link-hover" @click="openSystemInfo"
        >DiViNe Desktop: v{{ system.version }}</NuxtLink
      >
      <DialogInfo v-model="isVisible" :title="t('SystemInfo')" button-text="OK">
        <div style="font-size: 0.8em">
          <div><span style="font-weight: 600">DiViNe Desktop:</span> {{ system.version }}</div>
          <div><span style="font-weight: 600">Electron:</span> {{ system.electron }}</div>
          <div><span style="font-weight: 600">Node:</span> {{ system.node }}</div>
          <div><span style="font-weight: 600">Chrome:</span> {{ system.chrome }}</div>
          <div><span style="font-weight: 600">Platform:</span> {{ system.platform }}</div>
          <div><span style="font-weight: 600">Architecture:</span> {{ system.architecture }}</div>
          <br />
        </div>
        <p style="font-size: 0.9em; font-weight: 600; text-align: center">
          <!-- Electron limitation : only works on https links -->
          <NuxtLink
            :to="config.public.siteUrl + localeLink(config.public.desktopDownloadPage)"
            target="_blank"
            class="link-hover"
            >{{ $t('Download DiViNe Desktop for') }} Windows / MacOS / Linux</NuxtLink
          >
        </p>
        <div v-if="debug" style="font-size: 0.7em">
          <div><span style="font-weight: 600">app:</span> {{ system.appDir }}</div>
          <div><span style="font-weight: 600">exe:</span> {{ system.exeDir }}</div>
          <div><span style="font-weight: 600">bin:</span> {{ system.binDir }}</div>
          <div><span style="font-weight: 600">temp:</span> {{ system.tempDir }}</div>
          <div><span style="font-weight: 600">logs:</span> {{ system.logsDir }}</div>
          <div><span style="font-weight: 600">processCwd:</span> {{ system.processCwd }}</div>
        </div>
        <div id="logfile" style="font-size: 0.8em">
          <p style="font-weight: 600">log file:</p>
          <VTextarea v-model="logfile" label="main.log" readonly rows="4" />
        </div>
      </DialogInfo>
    </span>
    <!-- 👉 Footer: right content -->
    <span class="d-md-flex gap-x-4 text-primary d-none">
      <NuxtLink :to="localeLink('help')" target="noopener noreferrer">{{
        $t('SiteLayoutFooter.Help')
      }}</NuxtLink>
      <NuxtLink :to="localeLink('terms-of-service')" target="noopener noreferrer">{{
        $t('SiteLayoutFooter.Terms')
      }}</NuxtLink>
      <NuxtLink :to="localeLink('eula')" target="noopener noreferrer">{{
        $t('SiteLayoutFooter.EULA')
      }}</NuxtLink>
      <NuxtLink :to="localeLink('privacy-policy')" target="noopener noreferrer">{{
        $t('SiteLayoutFooter.Privacy')
      }}</NuxtLink>
      <NuxtLink :to="localeLink('site-contact')" target="noopener noreferrer">{{
        $t('SiteLayoutFooter.Contact')
      }}</NuxtLink>
    </span>
  </div>
</template>

<style lang="css" scoped>
#logfile textarea {
  font-size: 0.7em;
}
.footer-font {
  font-size: 0.85em;
}
.link-hover:not(:hover) {
  color: rgba(var(--v-theme-on-background), var(--v-high-emphasis-opacity));
}
</style>
